<template>
  <span>
    {{ timeAgo }}
    <!--        <tippy to="parent">-->
    <!--            {{ formatNiceDate(computedDate) }}-->
    <!--        </tippy>-->
  </span>
</template>

<script setup lang="ts">
const props = defineProps({
  date: {
    type: [Date, String],
    required: true,
  },
});

const computedDate = computed(() => {
  return new Date(props.date);
});

const timeAgo = computed(() => {
  return sinceDate(props.date);
});
</script>
