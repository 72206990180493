<template>
  <span>
    {{ timeRemaining }}
  </span>
</template>

<script setup lang="ts">
interface Props {
  date: Date | string;
}

const props = defineProps<Props>();

const timeRemaining = computed(() => {
  return untillDate(props.date);
});
</script>
